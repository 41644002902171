/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { Alert, Chip, Divider, Grid, Paper } from '@mui/material';
import { MdClearAll } from 'react-icons/md';
import MuiButton from '@mui/material/Button';
import { ArrowBackIosNew, ArrowForwardIos, CloudUpload } from '@mui/icons-material';
import apiReactive from '../../apis/apiReactive';
import { API_REACTIVE } from '../../apis/config';
import Loader from '../../components/Loader';
import SpinnerSubmit from './spinnerSubmit';
import Swal from 'sweetalert2';
import { history } from '../../history';
import renderHTML from 'react-render-html';

const drawerWidth = 240;

class SiswaTestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSaveJawaban: false,
      isError: false,
      isDrawerOpen: true,
      activeId: false,
      isLast: false,
      time: {},
      seconds: 7200,
      jadwal: {},
      materi: {},
      listSoal: []
    };
    this.timer = 0;
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    this.checkBreakpoint(this.props.breakpoint)
    // let timeLeftVar = this.secondsToTime(this.state.seconds);
    // this.setState({ time: timeLeftVar });

    // this.startTimer();
    const prevState = this.props.location.state;
    if (prevState !== undefined) {
      this.setState({ jadwal: prevState.jadwal, materi: prevState.materi })
      this.fetchSoal(prevState.jadwal.id, prevState.materi.materiId)
    }

  }

  fetchSoal = async (jadwalId, materiId) => {
    this.setState({ isLoading: true, listSoal: [] })
    const result = await apiReactive.post(API_REACTIVE.SOAL_FIND,
      {
        materiId: materiId,
        jadwalId: jadwalId,
        sample: false,
        page: 0,
        size: 100
      }
    )
    if (result) {
      if (result.data.resCode === API_REACTIVE.RESPONSE_CODE_SUCCESS) {
        const list = result.data.results;
        var waktu = result.data.result.timer;
        var split = waktu.split(":");

        var { activeId } = this.state;
        if (!activeId) {
          if (list.length > 0) {
            list.sort((a, b) => (a.noUrut > b.noUrut) ? 1 : -1)
            const first = list[0];
            console.log('listSoal === ', list)
            this.setState({
              listSoal: list,
              activeId: first.noUrut,
            })

            // trigger timer
            this.setState({
              time: waktu,
              seconds: Math.floor(split[0] * 3600) + Math.floor(split[1] * 60) + Math.floor(split[2])
            })

            this.startTimer();
          }
        }
        // this.setState({ listSoal: list, activeId: activeId })  
        // this.setState({activeId})                           
      }
      this.setState({ isLoading: false })
    }
  }

  toggleActive(id) {
    let { listSoal } = this.state;

    if (id === listSoal.length) {
      this.setState({ isLast: true })
    } else {
      this.setState({ isLast: false })
    }
    this.setState({ activeId: id })
  };

  toggleJawaban = async (jawaban, soalId) => {
    this.setState({ isSaveJawaban: true })
    const { jadwal, materi } = this.state;

    const result = await apiReactive.post(API_REACTIVE.SAVE_JAWABAN + jadwal.id + "/" + materi.materiId + '?isSubmit=false',
      [{
        soalId: soalId,
        jawaban: jawaban
      }]
    );
    if (result) {
      if (result.data.resCode === API_REACTIVE.RESPONSE_CODE_SUCCESS) {
        var soals = this.state.listSoal;

        var idx = soals.findIndex((row) => row.id === soalId);
        soals[idx].jawabanSiswa = jawaban;
        this.setState({ soals: soals });
      } else {
        this.setState({ isError: true });
        setTimeout(
          function () {
            this.setState({ isError: false });
          }
            .bind(this),
          1800
        );
      }
      this.setState({ isSaveJawaban: false })
    }
  }

  saveJawabanEssay = async (soalId) => {
    this.setState({ isSaveJawaban: true })
    const { jadwal, materi } = this.state;
    const jawaban = document.getElementsByName("jawabanEssay" + soalId)[0].value;
    const result = await apiReactive.post(API_REACTIVE.SAVE_JAWABAN + jadwal.id + "/" + materi.materiId + '?isSubmit=false',
      [{
        soalId: soalId,
        jawaban: jawaban.toLowerCase().trim()
      }]
    );
    if (result) {
      if (result.data.resCode === API_REACTIVE.RESPONSE_CODE_SUCCESS) {
        var soals = this.state.listSoal;

        var idx = soals.findIndex((row) => row.id === soalId);
        soals[idx].jawabanSiswa = jawaban;
        this.setState({ soals: soals });

      } else {
        this.setState({ isError: true });
        setTimeout(
          function () {
            this.setState({ isError: false });
          }
            .bind(this),
          1800
        );
      }
      this.setState({ isSaveJawaban: false })
    }
  }

  selectedJawaban(opsi, soalId) {
    const find = this.state.jawabanSiswa.find(item => item.soalId === soalId);

    if (find !== undefined && find.jawaban === opsi) {
      return true;
    }
    return false;
  }

  toggleNext = (action) => {
    let number = 0, isLast = false;
    const { activeId, listSoal } = this.state;

    if (action === 'next') {
      number = activeId + 1;
      if (listSoal.length === number) {
        // number = activeId;
        isLast = true;
      }
    }
    if (action === 'prev') {
      number = activeId - 1;
    }

    if (number <= 0)
      return false;

    this.setState({ activeId: number, isLast: isLast }, function () {
      console.log(this.state.isLast)
    });
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
    if (this.state.seconds === 0) {
      this.handleSubmit();
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      this.handleSubmit();
      clearInterval(this.timer);
    }
  }

  // toggleComplete(soalId) {
  //   return this.state.jawabanSiswa.find(item => item.soalId === soalId);
  // }

  confirmSubmit = () => {
    Swal.fire({
      title: 'Yakin selesaikan subtest ini ?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then((result) => {
      if (result.isConfirmed) {
        this.handleSubmit();
      }
    })
  }

  handleSubmit = async () => {
    this.setState({ isLoading: true })
    const { jadwal, materi } = this.state;
    const result = await apiReactive.post(API_REACTIVE.SAVE_JAWABAN + jadwal.id + "/" + materi.materiId + '?isSubmit=true',
      []
    );
    if (result) {
      if (result.data.resCode === API_REACTIVE.RESPONSE_CODE_SUCCESS) {
        Swal.fire({
          icon: 'success',
          title: result.data.message,
          text: 'Hasil pengerjaan kamu berhasil disimpan',
          showCloseButton: false,
          allowOutsideClick: false,
          timer: 1500
        });

        history.push({
          pathname: '/siswa/pre-test',
          from: '/siswa/test',
          state: { jadwal: jadwal }
        })

      } else {
        Swal.fire({
          title: 'Oops... ' + result.data.result,
          icon: 'error',
          text: result.data.message,
          showCloseButton: true,
          timer: 1500
        })
        this.setState({ isLoading: false })
      }

    }
  }

  render() {
    const { activeId, time, listSoal } = this.state;

    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <this.AppBar open={this.state.isDrawerOpen}
        // position="fixed"
        // sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, backgroundColor: '#175889' }}
        >

          <Toolbar >
            <Button outline className="mr-5" size="sm"
              onClick={() => this.setState({ isDrawerOpen: !this.state.isDrawerOpen })}>
              <MdClearAll size={25} />
            </Button>

            <Typography variant="h6" noWrap component="div">
              Waktu Tersisa
            </Typography>
            <Chip label={(time.m === undefined ? '0' : time.m) + ' menit ' + (time.s === undefined ? '0' : time.s) + ' detik'}
              sx={{
                borderRadius: '50px',
                bgcolor: '#da0c8b',
                color: '#fff',
                fontSize: 20,
                padding: 2,
                marginLeft: 2
              }}
            />
          </Toolbar>
        </this.AppBar>

        <this.Drawer open={this.state.isDrawerOpen}
          variant="permanent"
          anchor="left"
        >
          <Toolbar>

          </Toolbar>
          <div className='button-80-wrapper'>
            <Typography className='mt-2 text-white text-center'>
              PERTANYAAN YANG SUDAH DIJAWAB
            </Typography>

            <Grid className='mt-3'>
              {listSoal.map((item, i) =>
                <div key={i} className={'button-80' + (activeId === item.noUrut ? ' active' : '') + (item.jawabanSiswa === null ? '' : ' complete')}
                  onClick={this.toggleActive.bind(this, item.noUrut)}>
                  <div className='button-80-text'>{item.noUrut}</div>
                </div>
              )}
            </Grid>
          </div>
          <div style={{width: '100%', position: 'absolute', bottom: '0', display: 'flex', justifyContent: 'end', padding: '20px'}}>
            <Button outline size="sm"
              onClick={() => this.setState({ isDrawerOpen: !this.state.isDrawerOpen })}>
              <MdClearAll size={25} />
            </Button>
          </div>
        </this.Drawer>
        {this.state.isLoading ? <Loader /> :
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
          >
            <Toolbar />
            <Box component={Paper} elevation={3}
              sx={{ padding: '1rem', minHeight: 375 }}>
              {listSoal.map((soal, idx) =>
                <div key={idx} className={'soals ' + (activeId === soal.noUrut ? 'isActive' : '')}>
                  <Row>
                    <Col lg={4} md={4} sm={4} xs={12} ><strong>Nomor soal : {soal.noUrut} </strong></Col>
                    <Col lg={8} md={8} sm={8} xs={12} ><strong>{this.state.materi.materi}</strong></Col>
                  </Row>
                  <Divider />
                  {soal.isSoalText ? <div className="mt-3">{renderHTML(soal.soal)}</div> :
                    <div className="mt-3">
                      <img className='img-soal' alt='img' src={`data:image/png;base64,${soal.soalImage}`} />
                    </div>
                  }
                  {soal.jenisSoal.toLowerCase() === 'opsi' ?
                    <div className='list-jawaban' >
                      {soal.listJawaban.map((jawaban, key) =>
                        <div key={key} className='list-jawaban-items' style={{ pointerEvents: this.state.isSaveJawaban ? 'none' : '' }}>
                          <div className={'opsi-button' + (soal.jawabanSiswa === jawaban.opsi ? ' selected' : '')}
                            onClick={() => this.toggleJawaban(jawaban.opsi, soal.id)}>
                            {jawaban.opsi}
                          </div>
                          {soal.isJawabanText ?
                            <div className='item-text'>
                              {jawaban.jawabanText}
                            </div> :
                            <div className='item-text'>
                              <img className='img-jawaban' alt='img' src={`data:image/png;base64,${jawaban.jawabanImage}`} />
                            </div>
                          }
                        </div>
                      )}
                    </div> : <>
                      <Form inline>
                        <FormGroup className="mt-4">
                          <Input type="text" name={"jawabanEssay" + soal.id} defaultValue={soal.jawabanSiswa || ''} placeholder="....." />
                          <Button type="button" color="primary"
                            style={{ marginLeft: '10px', pointerEvents: this.state.isSaveJawaban ? 'none' : '' }}
                            onClick={() => this.saveJawabanEssay(soal.id)}>simpan
                          </Button>
                        </FormGroup>
                      </Form>

                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-2 mb-2"><span style={{ fontSize: '13px' }}>Klik tombol “simpan” setelah mengisi jawaban</span></Col>
                      </Row>
                    </>
                  }

                  {this.state.isSaveJawaban && <SpinnerSubmit />}
                </div>
              )}
              {this.state.isError && <Alert severity="error" variant="outlined">Gagagl menyimpan jawaban... Silakan coba lagi !!</Alert>}
            </Box>

            <Toolbar />
            <Grid container spacing={3} >
              <Grid item xs={12} justify="space-between">
                <MuiButton variant='contained' startIcon={<ArrowBackIosNew />} onClick={() => this.toggleNext('prev')}>
                  sebelumnya
                </MuiButton>
                {this.state.isLast ?
                  <MuiButton onClick={this.confirmSubmit} variant='contained' className="float-right" color="success" endIcon={<CloudUpload />}>
                    Submit
                  </MuiButton> :
                  <MuiButton variant='contained' className="float-right" endIcon={<ArrowForwardIos />}
                    onClick={() => this.toggleNext('next')}>
                    selanjutnya
                  </MuiButton>
                }

              </Grid>

            </Grid>
          </Box>
        }
      </Box>
    );
  }

  openedMixin = (theme) => ({
    // background: 'linear-gradient(to bottom, #6a82fb, #fc5c7d)',
    background: 'linear-gradient(0deg, rgba(253,187,45,1) 13%, rgba(162,170,57,1) 55%)',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  closedMixin = (theme) => ({
    // background: 'linear-gradient(to bottom, #6a82fb, #fc5c7d)',
    background: 'linear-gradient(0deg, rgba(253,187,45,1) 13%, rgba(162,170,57,1) 55%)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(0)} + 1px)`
  });

  AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    background: '#FFFF',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })
    }),
  }));

  Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      boxSizing: 'border-box',
      ...(open && {
        ...this.openedMixin(theme),
        '& .MuiDrawer-paper': this.openedMixin(theme),
      }),
      ...(!open && {
        ...this.closedMixin(theme),
        '& .MuiDrawer-paper': this.closedMixin(theme),
      }),
    }),
  );

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
        return this.setState({ isDrawerOpen: false });
      case 'sm':
        return this.setState({ isDrawerOpen: false });
      case 'md':
      case 'lg':
      case 'xl':
      default:
        return this.setState({ isDrawerOpen: true });
    }
  }

}

function mapStateToProps(state) {
  const { user } = state.auth;
  return { user };
}

export default connect(mapStateToProps)(SiswaTestPage);
