import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import React from 'react';
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import { clearMessage } from "../../actions/message";
import { history } from '../../history';
import EventBus from ".../../utils/eventBus";
import { Redirect } from 'react-router-dom';
import {
  MdClearAll,
  MdExitToApp
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Col
} from 'reactstrap';
import bn from 'utils/bemnames';
const bem = bn.create('header');



// const MdNotificationsActiveWithBadge = withBadge({
//   size: 'md',
//   color: 'primary',
//   style: {
//     top: -10,
//     right: -10,
//     display: 'inline-flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   children: <small>0</small>,
// })(MdNotificationsActive);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.state = {
      isOpenNotificationPopover: false,
      isNotificationConfirmed: false,
      isOpenUserCardPopover: false,
    };

    history.listen((location) => {
      // console.log(location, ' history changed...!!!')
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  componentDidMount() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    this.props.dispatch(logout());

    // this.setState({
    //   showModeratorBoard: false,
    //   showAdminBoard: false,
    //   currentUser: undefined,
    // });
  }


  toggleNotificationPopover = () => {
    // this.setState({
    //   isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    // });

    // if (!this.state.isNotificationConfirmed) {
    //   this.setState({ isNotificationConfirmed: true });
    // }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    // const { isNotificationConfirmed } = this.state;
    const { isLoggedIn, user } = this.props;
    if (!isLoggedIn) {
      return <Redirect to="/" />;
    }
    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav navbar>

        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          {user.roles[0] == 'ROLE_USER' ?
            <div style={{ paddingTop: '18px', textAlign: 'right' }}>
              {user.userProfile.firstName} </div>
            : null}


          {/* <NavItem className="d-inline-flex">
            <NavLink id="Popover1" className="position-relative">
              {isNotificationConfirmed ? (
                <MdNotificationsNone
                  size={25}
                  className="text-secondary can-click"
                  onClick={this.toggleNotificationPopover}
                />
              ) : (
                <MdNotificationsActiveWithBadge
                  size={25}
                  className="text-secondary can-click animated swing infinite"
                  onClick={this.toggleNotificationPopover}
                />
              )}
            </NavLink>
            <Popover
              placement="bottom"
              isOpen={this.state.isOpenNotificationPopover}
              toggle={this.toggleNotificationPopover}
              target="Popover1"
            >
              <PopoverBody>
                <Notifications notificationsData={notificationsData} />
              </PopoverBody>
            </Popover>
          </NavItem> */}

          <NavItem>
            <NavLink id="Popover2">
              {user.nama} &nbsp;
              <Avatar
                onClick={this.toggleUserCardPopover}
                className="can-click"
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title=""
                  subtitle={user.nama}
                  text={user.email}
                  className="border-light"
                >
                  <ListGroup flush>
                    {/* <ListGroupItem tag="button" action className="border-light">
                      <MdPersonPin /> Profile
                    </ListGroupItem> */}
                    <ListGroupItem tag="button" action className="border-light" onClick={this.logOut}>
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>

        {/* <AuthVerify logOut={this.logOut}/> */}
      </Navbar>
    );
  }
}

// export default Header;

function mapStateToProps(state) {
  const { isLoggedIn, user } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
    user
  };
}

export default connect(mapStateToProps)(Header);
