import logo200Image from 'assets/img/logo/logo-apps.png';
import bgLogin from 'assets/img/logo/bg-login.jpg';
import React from 'react';
import { Redirect } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { Card, Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { login } from "../actions/auth";
import CheckButton from "react-validation/build/button";
import { USER_ROLES } from '../utils/user-role';
import { history } from '../history';
import { PanoramaFishEye } from '@mui/icons-material';
import CustomInput from './CustomForm';
import { AiFillLock } from 'react-icons/ai'

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class AuthPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      showLogo: true,
      show: false,
    };
  }

  changeAuthState = authState => event => {
    event.preventDefault();
    this.props.onChangeAuthState(authState);
  };

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  showPassword() {
    this.state({ show: !this.state.show ? this.state.show : !this.state.show })
  }


  handleLogin = (e) => {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    this.form.validateAll();

    const { dispatch, user } = this.props;

    if (this.checkBtn.context._errors.length === 0) {
      dispatch(login(this.state.username, this.state.password))
        .then(() => {
          const role = user.roles.toString();
          if (role === USER_ROLES.ADMIN) {
            history.push("/adm_dashboard");
          }

          if (role === USER_ROLES.SEKOLAH) {
            history.push("/sekolah_dashboard");
          }

          if (role === USER_ROLES.SISWA) {
            history.push("/userhome");
          }

          window.location.reload();

        })
        .catch(() => {
          this.setState({
            loading: false
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { isLoggedIn, message, user } = this.props;
    if (isLoggedIn) {
      const role = user.roles.toString();
      if (role === USER_ROLES.ADMIN) {
        return <Redirect exact={true} to="/adm_dashboard" />;
      }

      if (role === USER_ROLES.SEKOLAH) {
        return <Redirect exact={true} to="/sekolah_dashboard" />;
      }

      if (role === USER_ROLES.SISWA) {
        return <Redirect exact={true} to="/userhome" />;
      }
    }
    require("./auth.css");
    const btnLoginStyle = {
      color: 'common.white',
      bgcolor: 'linear-gradient(90deg, rgba(162, 170, 57, 1) 23%, rgba(252, 154, 33, 1) 47%, rgba(255, 215, 0, 1) 94%)',
      '&:hover': {
        bgcolor: 'linear-gradient(90deg, rgba(255, 215, 0, 1) 23%, rgba(252, 154, 33, 1) 47%, rgba(162, 170, 57, 1) 94%)',
      },
    }
    return (
      <div className="bg-body"
        style={{
          height: '100vh',
          width: '100%',
          // justifyContent: 'space-around',
          alignItems: 'center',
          display: 'flex',
          // gap: '2%',
          background: 'linear-gradient(90deg, rgba(162, 170, 57, 1) 23%, rgba(252, 154, 33, 1) 47%, rgba(255, 215, 0, 1) 94%)'
        }}>
        <div className='pnl-bg' style={{ width: '50%', height: '100%', backgroundImage: 'url(' + bgLogin + ')' }}></div>
        <div className='pnl-form'>
          <div className='card-form' style={{
            // width: '400px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '8px',
            background: '#FFF',
            padding: '10px 0 30px 0',
            borderRadius: '25px'
          }}>
            {this.state.showLogo && (
              <div className="row text-center pb-4">
                <img
                  src={logo200Image}
                  className="rounded m-auto"
                  alt="logo" style={{ width: 220 }}
                />
              </div>
            )}
            <div className='form-login'>
              <Form onSubmit={this.handleLogin} className="formLogin"
                ref={(c) => {
                  this.form = c;
                }} width='340px'>

                <FormGroup>
                  <Label for="username">Username / NISN</Label>
                  <Input
                    type="text"
                    className="form-control"
                    name="username"
                    placeholder='Input username / NISN'
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                    validations={[required]}
                    style={{ borderRadius: '25px' }}
                  />
                </FormGroup>
                {/* <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type={this.state.show ? 'text' : 'password'}
                    className="form-control"
                    name="password"
                    placeholder='your password'
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required]}
                    style={{ borderRadius: '25px' }}
                  />
                </FormGroup> */}
                <Label for="password">Password</Label>
                <CustomInput type={'password'}
                  placeholder={'Input Password'}
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  validations={[required]}
                />
                <hr />
                <Button
                  size="lg"
                  type="submit"
                  className="btn-loginAuth border-0"
                  disabled={this.state.loading}
                  block
                  style={{
                    borderRadius: '25px',
                    background: 'linear-gradient(90deg, rgba(162, 170, 57, 1) 23%, rgba(252, 154, 33, 1) 47%, rgba(255, 215, 0, 1) 94%)',
                    "&:hover": {
                      background: "linear-gradient(90deg, rgba(255, 215, 0, 1) 23%, rgba(252, 154, 33, 1) 47%, rgba(162, 170, 57, 1) 94%)"
                    }
                  }}
                >

                  {this.state.loading ? (
                    <span className="spinner-border spinner-border"></span>
                  ) : <span>Masuk</span>}

                </Button>

                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={(c) => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
            </div>

          </div>

        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { isLoggedIn, user } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    user,
    message
  };
}

export default connect(mapStateToProps)(AuthPage);
